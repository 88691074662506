<template>
   <v-container fluid>
       <v-row justify="center">
           <v-col></v-col>
           <v-col cols="12" class="text-center">
               <v-icon size="90">mdi-chart-areaspline</v-icon>
               <h2>Resultados</h2>
           </v-col>
           <v-col cols="12" sm="12" class="pb-0 mb-0 text-center"> 
               <v-alert type="info" style="display: -webkit-inline-box;">
                    <h3> {{ calcularHuellaAgua() }} METROS CÚBICOS</h3>
                </v-alert>
           </v-col>
           <v-col cols="12" sm="12" md="8" class="pt-0 mt-0 text-center">
               <div class="ma-5"></div>
               <h2>Consumo de alimentos</h2>
               <GChart
                    v-if="$store.state.cereales"
                    type="ColumnChart"
                    :data="chartData"
                    :options="chartOptions"
                />
           </v-col>
           <v-col cols="12" sm="12" md="8" class="pt-0 mt-0 text-center">
               <div class="ma-5"></div>
               <h2>Consumo doméstico</h2>
               <GChart
                    v-if="$store.state.cereales"
                    type="ColumnChart"
                    :data="chartDataDomestica"
                    :options="chartOptions"
                />
           </v-col>
           <v-col cols="12" sm="12" md="8" class="pt-0 mt-0 text-center">
               <div class="ma-5"></div>
               <h2>Total</h2>
               <GChart
                    v-if="$store.state.cereales"
                    type="ColumnChart"
                    :data="total"
                    :options="chartOptions"
                />
           </v-col>
           <v-col cols="12" class="text-center">
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q0"
                    >
                    <v-icon left>mdi-chevron-left</v-icon> Regresar
                </v-btn>
           </v-col>
       </v-row>
   </v-container>
</template>

<script>
import Parse from 'parse'

export default {
    
    data() {
        return {
            chartData: [
                ['Categoría', 'Mts cúbicos', { role: 'style' } ],
                ['Cereales', (this.$store.state.cereales * 93), 'color: blue'],
                ['Carnes', (this.$store.state.carnes * 432), 'color: red'],
                ['Lacteos', (this.$store.state.lacteos * 59), 'color: grey'],
                ['Huevos', (this.$store.state.huevos * 10), 'color: yellow'],
                ['Comida', (this.$store.state.comida == 'promedio' ? 1 : this.$store.state.comida == 'alto' ? 1 : 0), 'color: orange'],
                ['Dulces', (this.$store.state.dulces == 'alto' ? 9 : this.$store.state.dulces == 'medio' ? 7 : 5), 'color: pink'],
                ['Verduras', (this.$store.state.verduras * 15), 'color: green'],
                ['Frutas', (this.$store.state.verduras * 56), 'color: chartreuse'],
                ['Tubérculo', (this.$store.state.tuberculos * 35), 'color: purple'],
                ['Cafe', (this.$store.state.cafe * 27.5), 'color: brown'],
                ['Té', (this.$store.state.tee * 27.5), 'color: black']                
            ],
            chartOptions: {
                chart: {
                    title: 'Consumo de agua',
                    subtitle: 'Huella hidrica',
                },
                height: 400,
                chartArea: {'width': '100%', 'height': '80%'},
                legend: {'position': 'bottom'}
            },

            chartDataDomestica: [
                ['Categoría', 'Mts cúbicos', { role: 'style' } ],
                ['Ducha', Number(this.$store.state.ducha) < 5 ? 37.85 : Number(this.$store.state.ducha) < 10 ? 75.70 : Number(this.$store.state.ducha) < 15 ? 124.91 : 143.84, 'color: blue'],
                ['Lavamanos', Number(this.$store.state.manos) < 5 ? 75.70 : Number(this.$store.state.manos) < 10 ? 151.41 : Number(this.$store.state.manos) < 30 ? 378.54 : 567.81, 'color: red'],
                ['Inodoro', (this.$store.state.inodoro == 'si' ? 30 : this.$store.state.inodoro == 'no' ? 94.63 : 64.35), 'color: grey'],
                ['Fregadero', (Number(this.$store.state.fregadero) < 5 ? 75.70 : Number(this.$store.state.fregadero) < 20 ? 246.05 : Number(this.$store.state.fregadero) < 45 ? 473.17 : 851.71), 'color: yellow'],
                ['Lavadora', (this.$store.state.lavadora == 'antigua' ? this.$store.state.lavadoraTime * 11.35  : this.$store.state.lavadora == 'eficiente' ? this.$store.state.lavadoraTime * 3.78 : this.$store.state.lavadoraTime * 7.57), 'color: orange'],
                ['Aguas grises', (this.$store.state.aguasgrises == 'si' ? -117.38 : 0), 'color: pink'],
            ],   

            total: [
                ['Categoría', 'Mts cúbicos', { role: 'style' } ],
                ['Alimentos', this.calcularAlimentos(), 'color: blue'],
                ['Doméstica', this.calcularDomestica(), 'color: red'],
            ],

            alimentos: 0,
            domestica: 0,
            totalFootPrint: 0
            
        }
    },

    mounted() {
        this.$store.state.progress = 100
        this.goBackIfEmpty()
    },

    computed: {
        
    },

    methods: {
       goBackIfEmpty(){
           if(!this.$store.state.municipio) {
                this.$router.push('/municipio')
           }else if(!this.$store.state.personas) {
                this.$router.push('/personas')
           }else if(!this.$store.state.cereales) {
                this.$router.push('/q1')
           }else if(!this.$store.state.carnes) {
               this.$router.push('/q2')
           }else if(!this.$store.state.lacteos) {
               this.$router.push('/q3')
           }else if(!this.$store.state.huevos) {
               this.$router.push('/q4')
           }else if(!this.$store.state.comida) {
               this.$router.push('/q5')
           }else if(!this.$store.state.dulces) {
               this.$router.push('/q6')
           }else if(!this.$store.state.verduras) {
               this.$router.push('/q7')
           }else if(!this.$store.state.frutas) {
               this.$router.push('/q8')
           }else if(!this.$store.state.tuberculos) {
               this.$router.push('/q9')
           }else if(!this.$store.state.cafe) {
               this.$router.push('/q10')
           }else if(!this.$store.state.tee) {
               this.$router.push('/q11')
           }else if(!this.$store.state.ducha) {
               this.$router.push('/q12')
           }else if(!this.$store.state.manos) {
               this.$router.push('/q13')
           }else if(!this.$store.state.inodoro) {
               this.$router.push('/q14')
           }else if(!this.$store.state.fregadero) {
               this.$router.push('/q15')
           }else if(!this.$store.state.lavadora) {
               this.$router.push('/q16')
           }else if(!this.$store.state.lavadoraTime) {
               this.$router.push('/q16')
           }else if(!this.$store.state.aguasgrises) {
               this.$router.push('/q17')
           }
       },

       calcularAlimentos(){
           return (this.$store.state.cereales * 93) + (this.$store.state.carnes * 432) + (this.$store.state.lacteos * 59) + (this.$store.state.huevos * 10) + (this.$store.state.comida == 'promedio' ? 1 : this.$store.state.comida == 'alto' ? 1 : 0) + (this.$store.state.dulces == 'alto' ? 9 : this.$store.state.dulces == 'medio' ? 7 : 5) + (this.$store.state.verduras * 15)  + (this.$store.state.frutas * 56) + (this.$store.state.tuberculos * 35) + (this.$store.state.cafe * 27.5) + (this.$store.state.tee * 27.5)
       },

       calcularDomestica(){
           return Number(this.$store.state.ducha) < 5 ? 37.85 : Number(this.$store.state.ducha) < 10 ? 75.70 : Number(this.$store.state.ducha) < 15 ? 124.91 : 143.84 + Number(this.$store.state.manos) < 5 ? 75.70 : Number(this.$store.state.manos) < 10 ? 151.41 : Number(this.$store.state.manos) < 30 ? 378.54 : 567.81 + (this.$store.state.inodoro == 'si' ? 30 : this.$store.state.inodoro == 'no' ? 94.63 : 64.35) + (Number(this.$store.state.fregadero) < 5 ? 75.70 : Number(this.$store.state.fregadero) < 20 ? 246.05 : Number(this.$store.state.fregadero) < 45 ? 473.17 : 851.71) + (this.$store.state.lavadora == 'antigua' ? this.$store.state.lavadoraTime * 11.35  : this.$store.state.lavadora == 'eficiente' ? this.$store.state.lavadoraTime * 3.78 : this.$store.state.lavadoraTime * 7.57) + (this.$store.state.aguasgrises == 'si' ? -117.38 : 0) 
       },

       calcularHuellaAgua(){
           if (this.$store.state.municipio) {
               var nf = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                });
                this.alimentos = this.calcularAlimentos()
                this.domestica = this.calcularDomestica()
                this.totalFootPrint = this.alimentos + this.domestica
                if (this.totalFootPrint) {
                    this.saveResult()
                }
    
               return nf.format(this.totalFootPrint)
           }
           return null
       },

       async saveResult(){
           try {
                let params = {
                    city: this.$store.state.municipio,
                    people: this.$store.state.personas,
                    cereal: this.$store.state.cereales,
                    meat: this.$store.state.carnes,
                    dairy: this.$store.state.lacteos,
                    eggs: this.$store.state.huevos,
                    fatFood: this.$store.state.comida,
                    confectionery: this.$store.state.dulces,
                    vegetables:this.$store.state.verduras ,
                    fruits:this.$store.state.frutas ,
                    tubers: this.$store.state.tuberculos,
                    coffee: this.$store.state.cafe,
                    tee: this.$store.state.tee,
                    shower: this.$store.state.ducha,
                    hands: this.$store.state.manos,
                    toilet: this.$store.state.inodoro,
                    sink: this.$store.state.fregadero,
                    washingMachine: this.$store.state.lavadora,
                    washingMachineTime: this.$store.state.lavadoraTime,
                    greyWaters: this.$store.state.aguasgrises,

                    foods: `${this.alimentos}`,
                    domestic: `${this.domestica}`,
                    total: `${this.totalFootPrint}`,
                    name: this.$store.state.user.name,
                    cellphone: this.$store.state.user.cellphone,
                    email: this.$store.state.user.email
                }
                await Parse.Cloud.run("calculateFootPrint", params);
            } catch (error) {
                this.$snotify.error(`${error}`, "Calculo huella hídrica", {
                timeout: 4000,
                preventDuplicates: true
                });
            }
       }
    },
}
</script>